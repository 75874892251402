import request from '@/utils/requests'

// 首页tab
export const channel_get_list = (data) => {
  return request({
    url: '/channel_web/get_list',
    method: 'get',
    params: data
  })
}

export const channel_get_info = data => {
  return request({
    url: '/channel_web/get_info',
    method: 'get',
    params: data
  })
}
