<template>
  <div class="banner">
    <van-swipe :autoplay="3000">
      <van-swipe-item v-for="image in data" :key="image.id" @click="toPlays(image.data_id)">
        <van-image :src="image.banner_pic" width="100%" lazy-load>
        </van-image>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>
<script>
export default {
  name: 'bannernew',
  components: {},
  props: ['data'],
  data() {
    return {
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    // 去播放页面
    toPlays(vod_id) {
      const query = {
        vod_id
      }
      this.$router.push({
        path: '/play',
        query
      })
    }

  }
}
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
}

.banner img {
  width: 100%;
}

::v-deep .van-swipe__track {
  height: 208.33px;
}
::v-deep .van-image__error, .van-image__img, .van-image__loading{
  height: auto;
}
</style>
