<template>
  <div>
    <div class="lnicio" name="lnicio">
      <div class="search">
        <div class="search__placeholder">
          <div class="search-content">
            <van-search v-model="value"
                        :placeholder="$t('placeholder')"
                        shape="round"
                        readonly
                        show-action
                        background="var(--main-color)"
                        @focus="onFocus"
            >
              <template #action>
                <div style="display:flex;">
                  <div class="search-action" @click="toAppDownloadList">
                    <van-image
                        :src="require('@/assets/download-index.png')"
                    />
                  </div>
                  <div v-show="isHi" class="search-action change-language"
                       @click="changeLanguageOverlayShow = true">
                    <van-image
                        :src="require('@/assets/change.png')"
                    />
                  </div>
                </div>
              </template>
            </van-search>
          </div>
        </div>
        <van-pull-refresh
            :pulling-text="$t('pullToRefresh')"
            :loosing-text="$t('loosing')"
            :loading-text="$t('loading')"
            v-model="isLoading"
            @refresh="onRefresh"
        >

          <div v-show="!tabLoading" style="display: flex;justify-content: center">
            <van-loading size="16px" style="top: 50px">Loading...</van-loading>
          </div>

          <van-tabs v-model="tabActive" :ellipsis="false" v-show="tabLoading" @click="tabsChange">
            <van-tab v-for="item in tabInfo" :title="item.channel_name" :key="item.id" line-width="13px"
                     line-height="2px">
              <van-list
                  v-model="channelListLoading"
                  :loading-text="$t('loading')"
                  :finished-text="$t('finish')"
                  :finished="channelListFinished"
                  @load="channelListOnload"
              >
                <div v-for="channelInfoItem in channelListData" :key="channelInfoItem.module_id">
                  <banner_new v-if="channelInfoItem.type === 1" :data="channelInfoItem.block_list"></banner_new>

                  <!-- 推荐板块(目前只有type=7的类型) -->
                  <div class="content" v-else-if="channelInfoItem.type === 7"
                       v-for="(item) in channelInfoItem.block_list" :key="item.module_id">
                    <div class="title">
                      {{ channelInfoItem.module_name }}
                    </div>
                    <div class="recommendContent">
                      <div class="screenMovie-type-7" v-for="index in item.vod_list" :key="index.id"
                           @click="toPlays(index.id)">
                        <div class="imgs-type-7" style="position:relative">
                          <van-image width="100%" radius="0.1rem" lazy-load :src="index.vod_pic">
                          </van-image>
                          <audioLangTag v-show="$app_country === 'hi'" :content="index.audio_language_tag"/>
                        </div>
                        <div class=" description-type-7">
                          {{ index.vod_name }}
                        </div>
                        <div class="optional-type-7">
                        </div>
                      </div>
                    </div>
                    <!-- 更多和换一换 -->
                    <div class="footers">
                      <div @click="toMore(item.topic_id)">
                        <span style="margin-left: .2rem;">{{ $t('more') }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- 专属导航 -->
                  <div class="content" v-else-if="channelInfoItem.type === 10">
                    <div class="title">
                      {{ channelInfoItem.module_name }}
                    </div>
                    <navigation :data="channelInfoItem.block_list"/>
                  </div>

                  <!-- 专题 -->
                  <div class="content" v-else-if="channelInfoItem.type === 9">
                    <special :data="channelInfoItem"></special>
                  </div>
                </div>
              </van-list>
            </van-tab>
          </van-tabs>
        </van-pull-refresh>
      </div>
    </div>
    <layout></layout>


    <!-- 更换语言 -->
    <van-overlay :z-index="999" :show="changeLanguageOverlayShow" @click="changeLanguageOverlayShow = false">
      <div class="wrapper" @click.stop>
        <div class="change-language-overlay">
          <div class="change-language-overlay-title">
            Select Language
          </div>
          <div class="change-language-overlay-content">
            <van-radio-group checked-color="red" v-model="appLanguage">
              <van-cell-group>
                <van-cell class="change-language-overlay-content-call"
                          v-for="(val , key) in appLanguageDict"
                          :key="val"
                          :title="key"
                          clickable
                          @click="appLanguage = val">
                  <template #right-icon>
                    <van-radio :name="val"/>
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
          </div>
          <div class="change-language-overlay-footer">
            <div class="change-language-overlay-footer-button" @click="changeLanguageOverlayShow=false">Cancel</div>
            <div class="change-language-overlay-footer-button change-language-overlay-footer-button-active"
                 @click="changeAppLanguage"
            >
              Save
            </div>
          </div>
        </div>
      </div>
    </van-overlay>


    <van-overlay :z-index="999" :show="downloadApkOverlayShow" @click="downloadApkOverlayShow = false">
      <div class="wrapper" @click.stop>
        <div class="change-language-overlay">
          <div class="change-language-overlay-title">Official notice</div>
          <div class="download-apk-overlay-content"> &nbsp;&nbsp;&nbsp;Since our application was maliciously removed
            from the Google App Market to prevent users from watching movies and TV shows, please download our latest
            application in a timely manner.
          </div>
          <div class="change-language-overlay-footer">
            <div class="download-apk-overlay-footer-button"
                 @click="toAppDownload()"
            >Download
            </div>
          </div>
        </div>
      </div>
    </van-overlay>

  </div>

</template>
<script>
import banner_new from '@/components/lnicio/banner_new' //轮播图组件页
import navigation from '@/components/lnicio/navigation' //轮播图组件页
import special from '@/components/lnicio/special'
import layout from '@/layout/index' //轮播图组件页
import audioLangTag from "@/components/audioLangTag";
import {lnicioLike} from '@/api/lnicio/youLike.js'
import {lnicioChenge} from '@/api/lnicio/change.js'
import {channel_get_list, channel_get_info} from "@/api/channel";
import {getCurrentDateAsYYYYMMDD} from "@/utils/init";
import {siteReport} from "@/api/log/log";

import {
  getLocalStorage,
  getNowTimestamp,
  setLocalStorage,
  getAppLanguage,
  isSetAppLanguage
} from "@/utils/init";

import noneImg from '@/assets/ic_default_varical.png' //三列图的默认图片
import noneImgS from '@/assets/猜你喜欢默认.png'
import {Toast} from "vant";  // 猜你喜欢默认图片

export default {
  name: 'home',
  components: {
    layout,
    banner_new,
    audioLangTag,
    navigation,
    special
    // recommend
  },
  data() {
    return {
      tabActive: 0,
      value: '',    //搜索框的值
      tabInfo: [],  //tab的数据集
      channelId: '', //频道ID
      recommend: [], //推荐数据 type是7的·
      bannerData: [],
      likeInfo: [], //猜你喜欢数据
      loading: false,
      finished: false,
      pn: 1, //传参的页数
      errorImg: noneImg,
      scrollTop: 0, //记录页面滑动的位置
      tabLoading: false,
      androidApkLink: 'https://version.hfyyc3232.com/version/freecineen/freecineen_10000-V2.0.6.apk',
      isLoading: false,
      cacheData: {
        channelList: [],
        channelInfo: {}
      },
      channelInfo: [],
      changeLanguageOverlayShow: false,
      downloadApkOverlayShow: false,
      appLanguage: 'en',
      appLanguageDict: {
        'English': 'en',
        'हिंदी': 'hi',
        'தமிழ்': 'ta',
        'తెలుగు': 'te',
        'മലയാളം': 'ml',
      },
      isHi: false,
      channelListPn: 1,
      channelListLoading: false,
      channelListFinished: false,
      channelListData: [],
      isLoadFinished: false
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  created() {
    this.getTab()
    this.appLanguage = getAppLanguage()
    this.isHi = this.$app_country === 'hi' ? true : false
    if (this.isHi) {
      // 获取用户是否设置app语言
      this.changeLanguageOverlayShow = !isSetAppLanguage()
    }
    // let hostname = window.location.hostname
    // if (hostname === 'www.freecine.tv') {
    //   this.downloadApkOverlayShow = true
    // } else {
    //   if (this.isHi) {
    //     // 获取用户是否设置app语言
    //     this.changeLanguageOverlayShow = ! isSetAppLanguage()
    //   }
    // }
  },
  mounted() {
    siteReport({
      'app_id': 'h5_report',
      'sitename': '1',
    })
  },
  methods: {
    messageAppLoadFinish() {
      if (! this.isLoadFinished) {
        this.isLoadFinished = true
        try {
          window.flutter_inappwebview.callHandler('loadAd');
        } catch (e) {
          console.log(e)
        }
      }
    },
    channelListOnload() {
      let pn = this.channelListPn
      let psize = 5
      let start = (pn - 1) * 5

      if (this.channelInfo.length <= 0) {
        var is_request = true
        var cacheData = getLocalStorage('index_cache', getNowTimestamp())
        if (cacheData) {
          cacheData = JSON.parse(cacheData)
          var channelInfo = cacheData.channelInfo
          this.cacheData.channelInfo = channelInfo

          var channelInfoById = channelInfo[this.channelId]
          if (channelInfoById) {
            this.channelInfo = channelInfoById

            let data = this.channelInfo.slice(start, start + psize)
            if (data.length > 0) {
              for (const item of data) {
                this.channelListData.push(item)
              }
              this.channelListLoading = false
            } else {
              this.channelListFinished = true
            }

            this.channelListPn = ++pn

            this.messageAppLoadFinish()

            is_request = false
          }
        }
        if (is_request) {
          const list = {
            channel_id: this.channelId,
            date: getCurrentDateAsYYYYMMDD()
          }
          channel_get_info(list).then(res => {
            if (res.code === 10000) {
              let channelInfo = res.result
              this.cacheData.channelInfo[this.channelId] = channelInfo

              // 添加本地缓存
              var now = getNowTimestamp()
              setLocalStorage('index_cache', JSON.stringify(this.cacheData), now + 86400 * 7)

              this.channelInfo = channelInfo
              console.log(this.channelInfo)

              let data = this.channelInfo.slice(start, start + psize)
              console.log(data)
              if (data.length > 0) {
                for (const item of data) {
                  this.channelListData.push(item)
                }
                this.channelListLoading = false
              } else {
                this.channelListFinished = true
              }

              this.channelListPn = ++pn
              this.messageAppLoadFinish()
            }
          })
        }
      } else {
        let data = this.channelInfo.slice(start, start + psize)
        if (data.length > 0) {
          for (const item of data) {
            this.channelListData.push(item)
          }
          this.channelListLoading = false
        } else {
          this.channelListFinished = true
        }

        this.channelListPn = ++pn
        this.messageAppLoadFinish()
      }
    },
    onRefresh() {
      this.getTab(true);
      this.tabActive = 0
    }
    ,
// 获取tab的数据
    async getTab(isRefresh = false) {
      var is_request = true
      if (!isRefresh) {
        var cacheData = getLocalStorage('index_cache', getNowTimestamp())
        if (cacheData) {
          cacheData = JSON.parse(cacheData)
          var channelList = cacheData.channelList
          if (channelList.length > 0) {
            this.cacheData.channelList = channelList

            this.channelId = channelList[0].id
            this.tabInfo = channelList
            this.channel_id = channelList[0].id
            this.tabLoading = true

            this.channelInfo = []
            this.channelListPn = 1
            this.channelListData = []
            this.channelListLoading = true
            this.channelListFinished = false
            this.channelListOnload()

            is_request = false
          } else {
            is_request = true
          }
        }
      }
      if (is_request) {
        // 清空代码里面的缓存数据，重新加载
        this.cacheData.channelList = []
        this.cacheData.channelInfo = {}
        channel_get_list({
          date: getCurrentDateAsYYYYMMDD()
        }).then(res => {
          if (res.code === 10000) {
            this.cacheData.channelList = res.result

            localStorage.removeItem('index_cache')

            this.channelId = res.result[0].id
            this.tabInfo = res.result
            this.channel_id = res.result[0].id
            this.tabLoading = true
            this.isLoading = false

            this.channelInfo = []
            this.channelListPn = 1
            this.channelListData = []
            this.channelListLoading = true
            this.channelListFinished = false
            this.channelListOnload()
          }
        })
      }
    },
   // 图片路径错误时
    setDefaultImage(event) {
      event.target.src = noneImg;
    },
    //猜你喜欢图片默认路径
    likeDefaultImage(event) {
      event.target.src = noneImgS
    },
    // 获取猜你喜欢数据
    async getLike() {
      const res = await lnicioLike({pn: 1})
      this.likeInfo = res.result
    },
    // 去更多页面
    toMore(topic_id) {
      const query = {
        topic_id: topic_id,
        pn: 1
      }
      this.$router.push({
        path: '/more',
        query: query
      })
    },
    //换一换
    change(index) {
      const strID = this.recommend[index].block_list[0].vod_list
      const not_vod_ids = strID.map(obj => obj.id).join(',');
      const topic_id = this.recommend[index].block_list[0].topic_id
      const list = {
        topic_id,
        psize: 6,
        not_vod_ids
      }
      lnicioChenge(list).then(res => {
        this.recommend[index].block_list[0].vod_list = res.result
      })
    },
    // 首页搜索框获得焦点时跳转到搜索页面
    onFocus() {
      document.activeElement.blur();
      localStorage.setItem('path', this.$route.path)
      this.$router.push('/search/historyRecommend')
    },
    toLiShi() {
      this.$router.push('/ViewingHistory')
    },
    // 去播放页面
    toPlays(vod_id) {
      const query = {
        vod_id
      }
      this.$router.push({
        path: '/play',
        query
      })
    },
    tabsChange(name) {
      let channel_id = this.tabInfo[name]['id']
      console.log(channel_id)
      this.channelId = channel_id
      this.channelInfo = []
      this.channelListPn = 1
      this.channelListData = []
      this.channelListLoading = true
      this.channelListFinished = false
      this.channelListOnload()
    },
    downloadApk() {
      window.location.href = this.androidApkLink
    },
    // 去app下载的事件
    toAppDownloadList() {
      try {
        window.flutter_inappwebview.callHandler('downloadList');
      } catch (error) {
        try {
          window.downloadList.postMessage('aaa');
        } catch (error) {
          console.log(error.message);
          Toast({
            message: this.$t('downloadTip'),
            position: 'bottom',
          });
        }
        console.log(error.message);
      }
    },
    changeAppLanguage() {
      localStorage.setItem('appLanguage', this.appLanguage)
      this.changeLanguageOverlayShow = false
      localStorage.removeItem('index_cache')
      localStorage.removeItem('streaming')
      this.$parent.excludeKeepAlive(['class', 'stream'])
      this.$i18n.locale = this.appLanguage
      this.getTab()

      // 调用app切换语言
      try {
        // 原生android
        window.Netandroid.appLanguageChange(this.appLanguage);
      } catch (error) {
        console.log(error.message);
      }
    },
    toAppDownload() {
      // this.downloadApkOverlayShow = false
      window.open('https://f34g9.nt73e.com/share/todosdays-habit/download.html', '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/lnicio/recommend.css';
@import '../../style/lnicio/youLike.css';

.imgs {
  margin-top: 10px;
}

.van-search__content {
  font-family: PingFang SC;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--title-color);
  background-color: var(--minor-color); // 设置输入框背景色
  border-radius: 5px;
}

/deep/ .van-search {
  background: none;
}

.search {
  //background-color: var(--main-color);
}

//tab样式
/deep/ .van-tabs__nav {
  //#020d19
  background: none;
}

// 栏目未选中样式
/deep/ .van-tab {
  font-family: PingFang SC;
  font-size: 17px;
  font-weight: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--title-color);
  opacity: 0.7;
}

// 栏目选中时样式
/deep/ .van-tab--active {
  font-size: 20px;
  font-weight: bold;
  color: var(--title-color);
  opacity: 1;
}

//tab底部小线条样式
/deep/ .van-tabs__line {
  //width: 100%;
  height: 0;
  background-color: var(--title-color);
  border-radius: 1px;
}

.imgs-type-7 {
  width: 116px;
  height: 164px;
}

/deep/ .van-tabs__nav--line {
  padding-bottom: 0.2rem;
}

/deep/ .van-tabs__nav--line.van-tabs__nav--complete {
  padding-left: 0;
}

.div-center {
  display: flex; /* 设置容器为Flex布局 */
  justify-content: center; /* 水平居中对齐子元素 */
  align-items: center; /* 垂直居中对齐子元素 */
}

/deep/ .van-swipe__indicator--active {
  background-color: var(--title-color);
}

/deep/ .van-search__action:active {
  background-color: var(--main-color);
}

.search-action {
  display: flex;
  justify-content: center;
  width: 25px;
  height: 25px;
}

.change-language {
  margin-left: 5px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.change-language-overlay {
  width: 250px;
  background-color: var(--main-color);
  border-radius: 5px;
}

.change-language-overlay-title {
  background-color: var(--minor-color);
  height: 50px;
  font-size: 20px;
  color: var(--title-color);
  align-content: center;
  text-align: center;
  line-height: 50px;
}

.change-language-overlay-footer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
}

.change-language-overlay-content {

}

.download-apk-overlay-content {
  font-size: 18px;
  color: white;
  padding: 10px;
  line-height: 25px;
}

.change-language-overlay-content-call {
  background-color: var(--main-color);
  color: var(--title-color);
}

.change-language-overlay-footer-button {
  font-size: 16px;
  color: red;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
  border: 1px solid red;
  width: 100px;
}

.change-language-overlay-footer-button-active {
  background-color: red;
  color: white;
  border: 1px solid red;
}

.download-apk-overlay-footer-button {
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
  border: 1px solid red;
  width: calc(100% - 20px);
  background-color: red;
  color: white;
}

.search__placeholder {
  height: 51px;
  width: 100%;
}
.search-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  border-bottom: 1px solid var(--minor-color)
}
</style>
