<template>
  <div class="navigation-content">
    <slidex>
      <slidexitem v-for="(item, key) in data" :key="item.topic_id">
        <div class="navigation-content-image" v-if="item.banner_pic">
          <van-image
              :src="item.banner_pic"
              lazy-load
              @click="topic(item.topic_id)"
          />
        </div>
        <div :class="textColorClass(key)" v-else @click="topic(item.topic_id)">
        {{item.block_name}}
        </div>
      </slidexitem>
    </slidex>
  </div>
</template>

<script>
import slidex from '@/components/slide/slide-x'
import slidexitem from '@/components/slide/slide-x-item'

export default {
  name: "navigation",
  props: ['data'],
  components: {
    slidex,
    slidexitem
  },
  methods: {
    textColorClass(num) {
      const index = num % 4;
      // 拼接类名
      return `navigation-content-text navigation-content-text-color-` + index;
    },
    // 去更多页面
    topic(topic_id) {
      const query = {
        topic_id: topic_id,
        pn: 1
      }
      this.$router.push({
        path: '/more',
        query: query
      })
    },
  }
}
</script>

<style scoped>
.navigation-content {
  padding: 5px 10px 0 10px;
}
.navigation-content-image{
  width: 140px;
  height: 78px;
}
.navigation-content-text{
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 2px;
  min-width: 60px;
  line-height: 30px;
  text-align: center;

}
.navigation-content-text-color-0 {
  background-color: rgb(251, 225, 224);
  color: rgb(198,126,135);
}

.navigation-content-text-color-1 {
  background-color: rgb(252, 241, 254);
  color: rgb(213,156,209);
}

.navigation-content-text-color-2 {
  background-color: rgb(243, 248, 254);
  color: rgb(154,176,220);
}
.navigation-content-text-color-3 {
  background-color: rgb(234, 246, 246);
  color: rgb(237,153,120);
}

/deep/ .slide-x {
  padding: 0;
}
/deep/ .van-image{
  display: block;
  width: 100%;
  height: 100%;
}
/deep/ .slide-x > div{
  margin-left: 10px;
}
/deep/ .slide-x> div:first-child {
  margin-left: 0;
}
</style>
