<template>
  <div>
    <div class="special-head" @click="topic(data.block_list[0].topic_id)">
      <div class="special-head-title">{{ data.module_name }}</div>
      <div class="special-head-subtitle">
        {{$t('moreWonderful')}} <van-icon name="arrow" />
      </div>
    </div>
    <div class="special-content">
      <slidex>
        <slidexitem v-for="item in data.block_list[0].vod_list" :key="item.id">
          <div class="special-content-item">
            <div class="special-content-image" @click="toPlays(item.id)">
              <van-image
                  :src="item.vod_pic"
                  lazy-load
              />
              <audioLangTag v-show="$app_country === 'hi'" :content="item.audio_language_tag"/>
            </div>

            <div class="special-content-title">
              {{ item.vod_name }}
            </div>
          </div>
        </slidexitem>
      </slidex>
    </div>
  </div>
</template>

<script>
import slidex from '@/components/slide/slide-x'
import slidexitem from '@/components/slide/slide-x-item'
import audioLangTag from "@/components/audioLangTag";

export default {
  name: "special",
  props: ['data'],
  components: {
    slidex,
    slidexitem,
    audioLangTag
  },
  methods: {
    // 去更多页面
    topic(topic_id) {
      console.log(222)
      const query = {
        topic_id: topic_id,
        pn: 1
      }
      this.$router.push({
        path: '/more',
        query: query
      })
    },
    toPlays(vod_id) {
      console.log(111)
      const query = {
        vod_id
      }
      this.$router.push({
        path: '/play',
        query
      })
    },
  }
}
</script>

<style scoped>
.special-head {
  font-size: 18px;
  line-height: 25px;
  color: var(--title-color);
  margin: 17px 10px 8px 10px;
  display: flex;
  justify-content: space-between;
}
.special-head-title {
  font-weight: bold;
}
.special-head-subtitle{
  font-size: 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.special-content {
  padding: 5px 10px 0 10px;
  color: white;

}
.special-content-item {
  width: 110px;
  position: relative;
}
.special-content-title {
  margin-top: 5px;
  font-size: 14px;
  line-height: 24px;
  color: var(--minor-text-color);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.special-content-image{
  width: 110px;
  height: 156px;
  overflow: hidden;
  border-radius: 3px;
}
/deep/ .slide-x {
  padding: 0;
}
/deep/ .van-image{
  display: block;
  width: 100%;
  height: 100%;
}
/deep/ .slide-x > div{
  margin-left: 5.5px;
}
/deep/ .slide-x> div:first-child {
  margin-left: 0;
}
</style>
