import request from '@/utils/requests'
import qs from 'qs'


// 换一换
export const lnicioChenge = data => {
  return request({
    url: '/topic/change',
    method: 'post',
    data:qs.stringify(data)
  })
}