import request from '@/utils/requests'
import qs from 'qs'

// 猜你喜欢
export const lnicioLike = data => {
  return request({
    url: '/search/recommend',
    method: 'post',
    data:qs.stringify(data)
  })
}